<style lang="less" scoped>
  @import "../../../../assets/css/variables";

  .content {
    margin: auto;
    width: 600px;
    .content-form {
      position: relative;
    }
    .image-content {
      font-size: @font-size-sm;
      color: @descFontColor;
    }
    .image-m-l {
      margin-left: 25px;
    }
    .order-image-wrapper {
      position: relative;
      height: 180px;
      width: 180px;
      border: 1px solid #e6f1fe;
      font-size: 100px;
      line-height: 160px;
      text-align: center;
      color: #ccc;
      .image-content {
        position: relative;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      .image-close {
        position: absolute;
        right: 0;
        top: 0;
        height: 20px;
        width: 20px;
        text-align: center;
        line-height: 20px;
        background-color: #1c2b36;
        color: #fff;
        font-size: @font-size-base;

      }
    }
  }
</style>

<template>
  <div class="page-wrapper">
  <el-breadcrumb separator="/">
    <el-breadcrumb-item >运维信息库</el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: '/service/repair/list' }">工单列表</el-breadcrumb-item>
    <el-breadcrumb-item :to="{ path: '/service/repair/detail'+'?id='+ this.$route.query.orderId }">工单详情</el-breadcrumb-item>
    <el-breadcrumb-item >反馈内容</el-breadcrumb-item>
  </el-breadcrumb>
  <div class="page-container">
    <form id="headImgfileForm" class="none" method="post" enctype="multipart/form-data">
      <input type="file" id="orderImageFile" @change="selectedImageFile" name="upfile" accept="image/*;capture=camera">
    </form>
    <div class="content">
      <el-form ref="orderForm" :rules="rules" :model="orderComment" class="w-full content-form">
        <el-form-item prop="content" class="m-b-sm" label="反馈内容">
          <el-input
            type="textarea"
            :rows="10"
            placeholder="请输入您的反馈内容"
            v-model="orderComment.content">
          </el-input>
        </el-form-item>
      </el-form>
      <div class="image-content m-t-md clearfix">
        <div class="m-b-md ">点击+可以上传反馈图片</div>
        <div @click="triggerSelectFile" class="order-image-wrapper pull-left">
          <span>+</span>
        </div>
        <div @click="delImage(index)"
             :class="['order-image-wrapper','pull-left', 'm-b-md',{'image-m-l':(index+1)%3!==0}]"
             v-for="(image,index) in imageList">
          <div class="image-content " :style="{'background-image':'url('+image.fileImage+')'}"
               v-loading.body="loading">
          </div>
          <div class="image-close">
            <i class="ion ion-close"></i>
          </div>
        </div>
      </div>
      <el-button @click="submitOrder" class="pull-right order-button w-ws m-b-xxl" type="primary">提&nbsp;&nbsp;交
      </el-button>
    </div>
  </div>
    </div>
</template>
<script>
  import {Notification} from "element-ui";
  import {getFileUrl} from "../../../../utils/tools/tools";
  import {uploadImageFile} from '../../../../services/common';
  import {mapGetters, mapActions} from "vuex";
  import {createOrderComment} from '../../../../services/order';

  export default{

    data(){
      return {
        orderComment: {
          orderId:'',
          content: '',
          images: '',
        },
        loading: true,
        imageList: [],   //上传的图片集合
        rules: {}
      }
    },
    computed: {
      ...mapGetters(
        {user: 'userInfo'}
      )
    },
    methods: {

      /**
       * 触发文件选择
       */
      triggerSelectFile(){
        document.querySelector('#orderImageFile').click();
      },
      /**
       * 选中
       */
      selectedImageFile($event){
        let files = $event.target.files;
        let file = null;
        let self = this;
        if (files && files.length) {
          file = files[0];
        }

        if (file && /^image\/\w+/.test(file.type)) {
          if ((file.size / 1024 / 1024 / 10) > 1) { //10M
            Notification.error({
              title: '错误',
              message: '请选择一个小于10M图片'
            });
            return;
          }
        } else {
          Notification.error({
            title: '错误',
            message: '请选择一个图片文件'
          });
          return;
        }
        //获取 URL.createObjectURL 地址
        const fileImageUrl = getFileUrl('#orderImageFile');
        let image = new Image();
        image.src = fileImageUrl;
        this.loading = true;
        image.onload = () => {
          this.imageList.push({fileImage: fileImageUrl, serverImage: ''});
          this.uploadImageFile(file);
        };
        image.onerror = () => {
          Notification.error({
            title: '错误',
            message: '请选择一个正确的图片文件'
          });
        };

      },
      /**
       * @param index 在imageList中的下标
       */
      delImage(index){
        this.imageList.splice(index, 1);
      },
      /**
       * 上传图片
       * @param imageFile Blob file
       */
      async uploadImageFile(imageFile){
        const uploadData = await uploadImageFile(imageFile);
        if (uploadData && uploadData.errorCode === 0
          && uploadData.data && uploadData.data.result) {
          //this.updateUserinfo(uploadData.data.fpicurl);
          this.imageList[this.imageList.length - 1].serverImage = uploadData.data.fpicurl;
          this.loading = false;
        }
      },

      /**
       * 创建一个反馈内容
       */
      async addOrderComment(){
        let images = [];
        this.imageList.forEach((item) => {
          images.push(item.serverImage);
        });
        this.orderComment.images = images.join(',');
        this.orderComment.orderId = this.$route.query.orderId;

        const responseData = await createOrderComment(this.orderComment);
        if (responseData && responseData.data && responseData.errorCode == 0) {
          Notification.success({title: '成功', message: '您的反馈已提交成功'});
          window.router.go(-1)
//          this.commentContent = '';
//          this.orderCommentList.push(responseData.data);
        }

      },
      /**
       * [提交表单回调事件]
       * @return {[type]} [description]
       */
      submitOrder(){
        this.$refs.orderForm.validate((valid) => {
          if (valid) {
            this.addOrderComment();
          }
        });
      }

    },
    async created(){

    }
  }
</script>
