/**
 * Created by zm on 17/5/27.
 *
 * 保修单的service封装
 *
 */
import * as API from "../utils/http/api";

/**
 1、创建之后：  Pending（待处理）
 2、分配之后：  Handling（处理中）
 3、确认需要操作：  Procurement（采购中）    （）
 4、采购到位后：  PurchaseComplete（采购到位处理中）
 5、订单完成后：  Done（已完成）
 6、确认完成后：  Closed（已关闭）
 */

/**
 * 获取自己创建的维修的订单
 * @param userId 用户id
 * @param status 状态
 * @param params 各种参数
 * @returns {Promise.<*>}
 */
export async function getOrderByCreateUser(
  userId,
  status,
  params = { size: 10, page: 1 }
) {
  try {
    if (status) {
      return await API.post("order/byCreateUser/" + userId, {
        status,
        ...params,
      });
    } else {
      return await API.post("order/byCreateUser/" + userId, { ...params });
    }
  } catch (error) {
    return error;
  }
}

/**
 * 助理获取自己管理的维修订单
 * @param userId 用户id
 * @param status 状态
 * @param params 各种参数
 * @returns {Promise.<*>}
 */
export async function getOrderByAssignUserId(
  userId,
  status,
  params = { size: 10, page: 1 }
) {
  try {
    if (status) {
      return await API.post("order/byAssisterUser/" + userId, {
        status,
        ...params,
      });
    } else {
      return await API.post("order/byAssisterUser/" + userId, { ...params });
    }
  } catch (error) {
    return error;
  }
}

/**
 * 区域经理获取自己管理的维修订单
 * @param userId 用户id
 * @param status 状态
 * @param params 各种参数
 * @returns {Promise.<*>}
 */
export async function getOrderByTeamId(
  userId,
  status,
  params = { size: 10, page: 1 }
) {
  try {
    if (status) {
      return await API.post("order/byTeamId/" + userId, { status, ...params });
    } else {
      return await API.post("order/byTeamId/" + userId, { ...params });
    }
  } catch (error) {
    return error;
  }
}

/**
 * 派单员获取自己管理的维修订单
 * @param status 状态
 * @param params 各种参数
 * @returns {Promise.<*>}
 */
export async function getOrderByExecuteUserId(
  userId,
  status,
  params = { size: 10, page: 1 }
) {
  try {
    if (status) {
      return await API.post("order/byExecuteUserId/" + userId, {
        status,
        ...params,
      });
    } else {
      return await API.post("order/byTeamId/" + userId, { ...params });
    }
  } catch (error) {
    return error;
  }
}

/**
 * 派单员获取自己管理的维修订单
 * @param status 状态
 * @param params 各种参数
 * @returns {Promise.<*>}
 */
export async function getOrderByStatus(status, params = { size: 10, page: 1 }) {
  try {
    return await API.post("order/byStatus/" + status, {
      ...params,
      sort: "createTime,desc",
    });
  } catch (error) {
    return error;
  }
}

//{userId}

/**
 * 创建一个报修订单
 * @param order
 * @returns {Promise.<*>}
 */
export async function createRepairOrder(order) {
  try {
    return await API.post("order", order);
  } catch (error) {
    return error;
  }
}

/**
 * 根据订单ID获取单个Order的信息
 * @param orderId  String
 * @returns {Promise.<*>}
 */
export async function getRepairOrderById(orderId) {
  try {
    return await API.get("order/detail/" + orderId);
  } catch (error) {
    return error;
  }
}

/**
 * 根据订单ID获取单个Order的信息
 * @param orderId  String
 * @returns {Promise.<*>}
 */

/**
 * 创建订单的评论
 * @param comment Object {orderId:订单ID,content:订单内容 ,images:图片集}
 * @returns {Promise.<*>}
 */
export async function createOrderComment(comment) {
  try {
    return await API.post("orderComment", comment);
  } catch (error) {
    return error;
  }
}

/**
 * 指派维修订单
 * @param orderId  订单id
 * @param userId  用户id
 * @returns {Promise.<*>}
 */
export async function repairOrderAssign(orderId, userId) {
  try {
    return await API.post("order/assign", {
      orderId,
      userId,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 完成维修单
 * @param orderId  订单id
 * @returns {Promise.<*>}
 */
export async function repairOrderDone(orderId) {
  try {
    return await API.post("order/done", {
      orderId,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 进入采购流程
 * @param orderId  订单id
 * @returns {Promise.<*>}
 */
export async function repairOrderProcurement(orderId, procurementRemarks) {
  try {
    return await API.post("order/procurement", {
      orderId,
      procurementRemarks,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 更新采购流程完成
 * @param orderId  订单id
 * @param isPass  Boolean false 审批拒绝 ture审批通过
 * @returns {Promise.<*>}
 */
export async function repairOrderPurchaseComplete(
  orderId,
  isPass,
  purchaseRemarks
) {
  try {
    return await API.post("order/purchaseComplete", {
      orderId,
      isPass,
      purchaseRemarks,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 是否关闭订单  true 确认完成，直接关闭  false 反之
 * @param orderId String  订单id
 * @param isDone Boolean 是否关闭
 * @returns {Promise.<*>}
 */
export async function checkIsDoneRepairOrder(orderId, isDone) {
  try {
    return await API.post("order/checkIsDone", {
      orderId,
      isDone,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 评价订单
 * @param rate Object {orderId:订单Id,score:评分,content:订单内容}
 * @returns {Promise.<void>}
 */
export async function createOrderEvaluate(
  rate = { orderId: -1, score: 3, content: "" }
) {
  try {
    return await API.post("orderEvaluate", rate);
  } catch (error) {
    return error;
  }
}

/**
 * 更新评价订单
 * @param rate Object {orderId:订单Id,score:评分,content:订单内容}
 * @returns {Promise.<void>}
 */
export async function updateOrderEvaluate(
  rate = { orderEvaluateId: -1, score: 3, content: "" }
) {
  try {
    return await API.post("orderEvaluate/update", rate);
  } catch (error) {
    return error;
  }
}

/**
 * 更新订单报修级别
 * @param orderId String
 * @param faultLevelId String
 * @returns {Promise.<void>}
 */
export async function updateOrderLevel(orderId, faultLevelId) {
  try {
    return await API.post("order/updateLevel", {
      orderId,
      faultLevelId,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 更新订单报修类型
 * @param orderId String
 * @param faultLevelId String
 * @returns {Promise.<void>}
 */
export async function updateOrderCategory(orderId, categoryId) {
  try {
    return await API.post("order/updateCategory", {
      orderId,
      categoryId,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 更新订单处理方式
 * @param orderId String
 * @param treatmentId String
 * @returns {Promise.<void>}
 */
export async function updateOrderTreatment(orderId, treatmentId) {
  try {
    return await API.post("order/updateTreatment", {
      orderId,
      treatmentId,
    });
  } catch (error) {
    return error;
  }
}

/**
 * 获取所有处理方式
 */
export async function getAllTreatment() {
  try {
    return await API.get("orderTreatment");
  } catch (error) {
    return error;
  }
}

/**
 * 获取所有供应商
 */
export async function getAllSupplier(params, page = { size: 999, page: 1 }) {
  try {
    return await API.post("supplier/bySearch", { ...params, ...page });
  } catch (error) {
    return error;
  }
}

/**
 * 一级审批
 * @param params{orderId:订单id,isApprove:是否通过,isNeedNextApprove:是否下级审批}
 * @returns {Promise.<*>}
 */
export async function levelOneApprove(params) {
  try {
    return await API.post("order/levelOneApprove", { ...params });
  } catch (error) {
    return error;
  }
}

/**
 * 二级审批
 * @param params{orderId:订单id,isApprove:是否通过}
 * @returns {Promise.<*>}
 */
export async function levelTwoApprove(params) {
  try {
    return await API.post("order/levelTwoApprove", { ...params });
  } catch (error) {
    return error;
  }
}

/**
 * 修改订单中的供应商信息
 * @param supplier Object {orderId:订单id,supplier:供应商名称,supplierPurchaseTime:通知时间, supplierArriveTime:处理时间}
 * @returns {Promise.<*>}
 */
export async function updateOrderSupplierInfo(supplier) {
  try {
    return await API.post("order/updateSupplierDetail", supplier);
  } catch (error) {
    return error;
  }
}

/**
 * 根据状态条件导出excel文件
 * @param status，params
 * @returns {Promise.<*>}
 */
export async function exportOrderExcel(status, params) {
  try {
    return await API.post("order/exportByStatus/" + status, { ...params });
  } catch (error) {
    return error;
  }
}

/**
 * 根据状态条件导出excel文件
 * @param teamId，params
 * @returns {Promise.<*>}
 */
export async function exportOrderXls(teamId, params) {
  try {
    return await API.post("order/exportByTeamId/" + teamId, { ...params });
  } catch (error) {
    return error;
  }
}

/**
 * 修改订单中的处理意见
 * @param contentInfo Object {orderId:订单id,supplier:处理意见与内容,treatmentContent}
 * @returns {Promise.<*>}
 */
export async function updateOrderContent(contentInfo) {
  try {
    return await API.post("order/updateTreatmentContent", contentInfo);
  } catch (error) {
    return error;
  }
}

/**
 * 修改订单中的处理结论
 * @param conclusionInfo Object {orderId:订单id,supplier:处理结论,treatmentConclusion}
 * @returns {Promise.<*>}
 */
export async function updateOrderConclusion(conclusionInfo) {
  try {
    return await API.post("order/updateTreatmentConclusion", conclusionInfo);
  } catch (error) {
    return error;
  }
}

/**
 * 修改订单中的处理结果
 * @param resultInfo Object {orderId:订单id,supplier:处理结果,treatmentResult}
 * @returns {Promise.<*>}
 */
export async function updateOrderResult(resultInfo) {
  try {
    return await API.post("order/updateTreatmentResult", resultInfo);
  } catch (error) {
    return error;
  }
}
